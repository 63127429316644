import { inNative, isIOS } from '../utils/env';
import { getLocalStorage } from './localStorage';
import { isMobile } from './os';
import qs from './urlParam';

let UID;
let SA_RELATION_ID; // SA来源信息
// bridge获取埋点公共参数
async function getCommonParams() {
  let globalParams = window.getGlobalParams || {};

  if (inNative) {
    if (!UID) {
      try {
        window.SodaBridge &&
          (await window.SodaBridge('getUserInfo').then((res) => {
            const { uid = -1 } = res.data || {};
            UID = uid;
          }));
      } catch (err) {
        UID = -1;
      }
    }
    if (!SA_RELATION_ID) {
      try {
        window.SodaBridge &&
          (await window.SodaBridge('getGlobalParams', 'badcase').then((res) => {
            const { relationId = -999 } = res.data || {};
            SA_RELATION_ID = relationId;
          }));
      } catch (err) {
        SA_RELATION_ID = -999;
      }
    }
  }

  const system = inNative ? (isIOS ? 'ios' : 'android') : isMobile() ? 'phone_web' : 'pc_web';
  return {
    user_uid: globalParams.uid || getLocalStorage('uid') || UID,
    uid: globalParams.uid || getLocalStorage('uid') || UID,
    user_phone: globalParams.phone,
    user_osVersion: globalParams.osVersion,
    user_clientType: globalParams.clientType,
    user_deviceType: globalParams.deviceType,
    poi_latitude: globalParams.poiLat || qs('poiLat') || 0,
    poi_longitude: globalParams.poiLng || qs('poiLng') || 0,
    op_system: system,
    poi_id: globalParams.poiId || getLocalStorage('poiId'),
    poi_city_id: globalParams.poiCityId || getLocalStorage('poiCityId'),
    country_code: qs('country_code') || '',
    path: window.location.href,
    pub_relation_id: SA_RELATION_ID || -999,
  };
}

export default async function trackEvent({
  eventId = '',
  eventLabel = '',
  attrs = {},
  callback = () => {},
}) {
  let globalParams = await getCommonParams();
  if (eventId === 'tone_p_x_login_success') {
    try {
      window.Omega.setTelephone && window.Omega.setTelephone(attrs.phone);
    } catch (e) {
      console.log(e);
    }
  }

  window.Omega &&
    window.Omega.trackEvent &&
    window.Omega.trackEvent(
      eventId,
      eventLabel,
      {
        ...globalParams,
        ...attrs,
      },
      null,
      (res) => {
        callback(res);
      }
    );
}
