// locale的分隔 符
const separator = '-';
// 可支持的语言列表
const LanguageMap = ['zh', 'en', 'es', 'pt', 'ja'];
// 可支持的国家列表
const CountryMap = ['MX', 'BR', 'JP', 'CR', 'CO', 'DO', 'CL', 'PE'];
// 可支持的locale列表，特殊处理支持es-419 en-US zh-CN语言
const LocaleMap = ['es-419', 'en-US', 'zh-CN'];
// 国家对应的默认语言对应关系
const CountryLanguageMap = {
  MX: 'es-MX',
  BR: 'pt-BR',
  JP: 'ja-JP',
  CR: 'es-419',
  CO: 'es-419',
  DO: 'es-419',
  CL: 'es-419',
  PE: 'es-419',
};
const CountryNameMap = {
  CN: 'China',
  MX: 'Mexico',
  BR: 'Brazil',
  JP: 'Japan',
  CR: 'CostaRica',
  CO: 'Colombia',
  DO: 'Dominican',
  CL: 'Chile',
  PE: 'Peru',
};
// 国家对应的默认locale对应关系
const CountryLocaleMap = {
  MX: 'es-MX',
  BR: 'pt-BR',
  JP: 'ja-JP',
  CR: 'es-CR',
  CO: 'es-CO',
  DO: 'es-DO',
  CL: 'es-CL',
  PE: 'es-PE',
};
/**
 * 可支持的语言包列表，根据语言首先进行划分
 * 如果支持特殊配置，可追加，否则走默认逻辑
 */
const LanguagePackMap = {
  en: {
    'en-US': 'en-US',
    default: 'en-US',
  },
  es: {
    'es-MX': 'es-MX',
    'es-CO': 'es-CO',
    default: 'es-419',
  },
  pt: {
    'pt-BR': 'pt-BR',
    default: 'pt-BR',
  },
  ja: {
    'ja-JP': 'ja-JP',
    default: 'ja-JP',
  },
  zh: {
    'zh-CN': 'zh-CN',
    default: 'zh-CN',
  },
};

// 根据Lang与Country，匹配得出支持的locale
LanguageMap.forEach((lang) => {
  CountryMap.forEach((country) => {
    LocaleMap.push(`${lang}${separator}${country}`);
  });
});

/**
 * 校验locale是否合理方法
 * @param {*} locale 需要被校验的locale
 * @returns 返回校验通过的locale
 */
const checkLocale = (locale = '') => {
  let finalLocale = null;
  let lowerCaseLocale = locale.toLowerCase();
  LocaleMap.forEach((localeItem) => {
    if (localeItem.toLowerCase() === lowerCaseLocale) {
      finalLocale = localeItem;
    }
  });
  return finalLocale;
};

/**
 * @description 获取符合预期的locale
 * @param {string} targetPath 支持传入目标路径与直接从url中获取两种locale处理规则
 * @return {string} locale 符合预期的locale
 */
const getLocale = (targetPath = '') => {
  var locale = null;
  var path = targetPath || location.pathname.split('/')[1];
  locale = checkLocale(path);
  if (!locale) {
    locale = checkLocale(window.navigator.language);
  }
  if (!locale) {
    // 兜底逻辑沿用en-US
    locale = `en${separator}US`;
  }
  return locale;
};

/**
 * 获取locale对应的语言包名称与类型
 * @param {*} locale 期望做匹配的locale，会再做一次合理性校验
 * @returns {String} short 语言简写，如element-ui、r-fusion-ui中会使用到
 * @returns {String} long:语言包全称，项目内i18n语言包会使用到
 */
const getLanguage = (locale = '') => {
  const finalLocale = getLocale(locale);
  const language = finalLocale.split(separator)[0];

  // 如果支持对应的语言，又存在对应locale的特殊配置，则直接返回
  if (LanguagePackMap[language] && LanguagePackMap[language][finalLocale]) {
    return {
      short: language,
      long: LanguagePackMap[language][finalLocale],
    };
    // 否则返回该语言的默认配置
  } else {
    return {
      short: language,
      long: LanguagePackMap[language]['default'],
    };
  }
};

/**
 * 获取locale对应的语言包长名称
 * @param {*} locale 期望做匹配的locale，会再做一次合理性校验
 * @returns {String} 语言包全称，项目内i18n语言包会使用到
 */
const getLanguageLong = (locale = '') => {
  const finalLocale = getLocale(locale);
  const language = finalLocale.split(separator)[0];

  // 如果支持对应的语言，又存在对应locale的特殊配置，则直接返回
  if (LanguagePackMap[language] && LanguagePackMap[language][finalLocale]) {
    return LanguagePackMap[language][finalLocale];
    // 否则返回该语言的默认配置
  } else {
    return LanguagePackMap[language]['default'];
  }
};

/**
 * 获取locale对应的语言包短名称
 * @param {*} locale 期望做匹配的locale，会再做一次合理性校验
 * @returns {String} 语言简写，如element-ui、r-fusion-ui中会使用到
 */
const getLanguageShort = (locale = '') => {
  const finalLocale = getLocale(locale);
  const language = finalLocale.split(separator)[0];
  return language;
};

/**
 * 获取locale对应的region
 * @param {*} locale 期望做匹配的locale，会再做一次合理性校验
 * @returns {String} region 区域/国家code
 */
const getRegion = (locale = '') => {
  const finalLocale = getLocale(locale);
  const region = finalLocale.split(separator)[1];

  // 不再做特殊处理，419区域也会被返回
  return region;
};

const getCountryNameByLocale = (locale = '') => {
  const region = getRegion(locale);
  // 根据map返回对应全称
  return CountryNameMap[region] || '';
};

export {
  getLocale,
  getLanguage,
  getLanguageLong,
  getLanguageShort,
  getRegion,
  getCountryNameByLocale,
  LanguageMap,
  CountryMap,
  LocaleMap,
  LanguagePackMap,
  CountryLanguageMap,
  CountryLocaleMap,
  CountryNameMap,
};
