/**
 * http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=771835355
 * Service Worker
 * 使用workbox实现 由以下三个文件配合
 * 1、vue.config.js GenerateSW  实现 缓存策略和缓存文件配置
 * 2、src/utils/sw-register.js  实现 sw注册 & sw注销 功能
 * 3、server/middlewares/serviceWorker.js  实现 sw 文件路径访问重定向
 */

const isPro = process.env.NODE_ENV === 'production' && process.env.BUILD_ENV !== 'development';
import { getLocale } from './locale';

export default function swRegister() {
  if (isPro && 'serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      let landing = isPro ? `/${getLocale()}/landing` : '';
      navigator.serviceWorker
        .register(`${landing}/sw-landing.js`)
        .then((registration) => {
          console.log('SW registered: ', registration);
        })
        .catch((registrationError) => {
          console.log('SW registration failed: ', registrationError);
        });
    });
  }
}

export function swUnregister() {
  if ('serviceWorker' in navigator) {
    try {
      caches.keys().then((keys) => {
        keys.forEach((key) => {
          caches.delete(key);
        });
      });
    } catch (error) {
      console.error(error);
    }
    navigator.serviceWorker.ready.then((registration) => {
      registration.unregister();
    });
  }
}
