// 参考：http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=860906794
// 页面完全加载时间上报时机是document加载完成，计算口径：pageLoadTime: performance.timing.loadEventStart - performance.timing.navigationStart
import trackEvent from './track.js';
import { util } from '@didi/soda-common';
const { env } = util;

const time = 1000 * 8; //  大于8秒的数据不统计在内，视为脏数据

function performanceStatisticsFn() {
  if (!env.inNative) return;
  window.addEventListener('load', function () {
    try {
      const { loadEventStart = 0, navigationStart = 0 } = performance?.timing || {};
      const timeDiff = loadEventStart - navigationStart;

      if (timeDiff > time) return;

      clientPerformanceStatistics({
        project_name: 'landing-page',
        t_pgrd: timeDiff,
      });
    } catch {
      console.log('------------------------------------');
    }
  });
}

function clientPerformanceStatistics(attrs, callback) {
  trackEvent({
    eventId: 'sailing_client_performance_statistics_bt',
    eventLabel: '统计端内h5页面行完全加载耗时',
    attrs,
    callback,
  });
}

performanceStatisticsFn();
