// import { resolve } from 'core-js/fn/promise';
// import { ssrCompile } from 'vue-template-compiler';
import * as env from '../utils/env';
// import DDSDK from '@didi/didi-sdk';
const DDbridge = {};
DDbridge.registerDefaultBridge = function () {
  if (!env.inNative) {
    /* eslint-disable */
    SodaBridgeRegister({
      Soda: {
        getUserInfo (params = {}, callback = function(){}) {
          callback && callback({})
        },
        getSystemInfo (params = {}, callback = function(){}) {
          callback && callback({})
        },
        getLocationInfo (params = {}, callback = function(){}) {
          try {
            new Promise((resolve, reject) => {
              navigator.geolocation.getCurrentPosition(function(position, error) {
                if (position) {
                  resolve(callback({
                    errno: 0,
                    errmsg: 'ok',
                    data: {
                      poiLat: position.coords.latitude,
                      poiLng: position.coords.longitude
                    }
                  }))
                } else {
                  reject(callback({
                    errno: 1,
                    errmsg: error.toString(),
                    data: {}
                  }))
                }
              })
            })
            // DDSDK.geoLocation({}, (error, result) => {
            //   // 如果无错误信息 则为定位成功; 否则为定位失败
            //   if (!error) {
            //     callback && callback(result)
            //   } else {
            //     callback && callback({
            //       errno: 1,
            //       errmsg: error.toString(),
            //       data: {}
            //     })
            //   }
            // })
          } catch (e) {
            callback && callback({
              errno: 1,
              errmsg: e.toString(),
              data: {}
            })
          }
        },
        getGlobalParams (params = {}, callback = function(){}) {
          callback && callback({})
        }
      }
    })
  }
}


DDbridge.install = function (Vue, options) {
  Vue.mixin({
    methods: {
      async SodaBridge (type, options) {
        const globalParams= await window.SodaBridge('getGlobalParams', 'badcase') || {}
        window.getGlobalParams = globalParams.data
        return window.SodaBridge(type, options)
      },
      SodaBridgeData (type, options) {
        return window.SodaBridge(type, options).then(pms => {
          return pms.data || {}
        })
      },
      // 每次埋点时需要执行的函数，用于获取this.userInfo.uid 以及this.systemInfo.getSystemInfo
      async setUserInfo () {
        if (!this.userInfo || !this.userInfo.hasOwnProperty('uid')) {
          this.userInfo = await this.SodaBridgeData('getUserInfo')
        }
        if (!this.systemInfo || !this.userInfo.hasOwnProperty('uid')) {
          this.systemInfo = await this.SodaBridgeData('getSystemInfo')
        }
      },
      /**
        * 修改标题
        */
      asyncSetTitle (params) {
        const { navi_title, timeout } = params
        // 延时设置标题，处理ios设置不成功问题
        setTimeout(() => {
          // 安卓ua有问题, 暂时用两种方式直接修改title，用于处理不在soda内
          if (Fusion && Fusion['setTitle']) {
            Fusion['setTitle']({ 'navi_title': navi_title })
          }
          this.SodaBridge('setTitle', { 'navi_title': navi_title })
        }, timeout)
      }
    },
    computed: {
      params () {
      let paramsString = window.location.search.slice(1)
      var items = paramsString.split('&')
      var result = {}
      items.forEach(item => {
      let temp = item.split('=')
      result[temp[0]] = temp[1]
      })
      return result
      }
    }
  })
}

export default DDbridge
