import { getLanguageLong, getRegion } from './locale';
const host = location.host;
const is99App = !!host.match('99app.com');

function setLanguage() {
  window._ENV_LANGUAGE_ = getLanguageLong();
}

function setBrand() {
  const region = getRegion();
  if (region === 'BR' || is99App) {
    window._ENV_BRAND_ = '99';
    window.document.documentElement.setAttribute('data-theme', '99');
  } else {
    window._ENV_BRAND_ = 'didi';
    window.document.documentElement.setAttribute('data-theme', 'didi');
  }
}

setLanguage();
setBrand();
