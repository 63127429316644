import Vue from 'vue';
import '@didi/soda-jsbridge';
import { interceptDialog, isEmbedded } from './lib/js/notTravelDialog.js';
import './lib/js/performance-statistics.js';
import './lib/utils/i18n';
import rpubWidget from '@didi/rlab-xpub-components/dist/h5/index.js';
import '@didi/rlab-xpub-components/dist/h5/index.css';
import ObserverLog from '@didi/tools-observer-log';
import { genRenderFnForH5 } from '@didi/dpub-render';

import './lib/css/reset.css';
import './lib/css/base.scss';
import initStyle from './lib/utils/style';
import DDBridge from './lib/js/DDBridge';
import getData from './lib/js/initData';
import OmegaTracker from '@didi/omega-tracker/lib/index.esm';
import swRegister, { swUnregister } from './lib/utils/sw-register';

// start - 新的appKey-1、只用来统计页面性能指标(不包含业务埋点) 2、将涉及的业务埋点下添加此appKey的应用(①DE报表②业务跑数在用需迁移) 2023.2.22
const omegaPageConfig = {
  appKey: 'omega9eba1c97f4', // 仅为landingpage单独申请设置的appKey
  uploadHost: 'omgup.didiglobal.com',
  browserRouterEnable: true, // SPA History pop PV上报
  autoClick: false, // 取消自动点击上报
  autoPosition: false,
};
const Omega = OmegaTracker.getTracker(omegaPageConfig);
window.Omega = Omega;
// end - 新的appKey-1、只用来统计页面性能指标(不包含业务埋点) 2、将涉及的业务埋点下添加此appKey的应用
import trackEvent from './lib/js/track';
window.trackEvent = trackEvent;

Vue.config.productionTip = false;
DDBridge.registerDefaultBridge();
Vue.use(DDBridge);

// 曝光事件唯一值，后续如果有新的曝光埋点，需要在这里添加埋点id和唯一值
const eventMap = {
  sailing_c_x_activity_landing_item_sw: 'item_id', // 首页活动落地页菜品实曝光
};

Vue.use(
  ObserverLog,
  eventMap,
  trackEvent,
  // 设置阈值为元素露出1px所对应的比例，至少露出1px
  {
    threshold: [0.01],
  }
);

swRegister();
// swUnregister()

getData().then((res) => {
  initStyle(res);
  if (isEmbedded) {
    interceptDialog();
    return;
  }
  new Vue({
    el: '#app',
    render: genRenderFnForH5(res, { ...rpubWidget }),
  });
});
