import queryString from 'query-string';
import { isEnv, isOnlineSchema } from './env.js';
import Dajax from '@didi/dajax';
import { getXpubConfig } from '../../api/index.js';
import trackEvent from './track.js';

const { preview } = queryString.parse(window.location.search);

let { s, activity_id } = queryString.parse(window.location.search);
// 判断字符串是内是否都是数字
function isAllDigits(str) {
  const pattern = /^\d+$/;
  return pattern.test(str);
}
// https://didi-food.com/es-PE/landing?s=op-json-sh7wguMQ8__v2&activity_id=2.461426971e+09
// 2.461426971e+09 js获取到的value 2.461426971e 09
if (!isAllDigits(activity_id) && activity_id) {
  activity_id = Number(activity_id?.replace(' ', '+'))?.toString();
}
const suffix = '__v2';
const isV2 = s?.indexOf(suffix) !== -1;

const apiSchemaApiUrl = {
  online: isV2
    ? 'https://img0.didiglobal.com/static/dpubimg/'
    : '//img0.didiglobal.com/static/soda_static/',
  test: 'https://gift-pre-inter.didistatic.com/static/anything/',
};

let dataApi = apiSchemaApiUrl.online;
if (preview === 'true') {
  dataApi = apiSchemaApiUrl.online;
}
if (isEnv) {
  dataApi = apiSchemaApiUrl.test;
}
if (isOnlineSchema) {
  dataApi = apiSchemaApiUrl.online;
}
if (isV2) {
  s = s?.replace(suffix, '');
}
function XpubJsonMonitorBt(res) {
  if (activity_id) {
    trackEvent({
      eventId: 'sailing_act_api_xpub_json_monitor_bt',
      eventLabel: 'api获取活动json报错',
      attrs: {
        activity_id: activity_id,
        page_url: window.location.herf,
        trace_id: res?.traceId,
        request_errno: res?.errno,
        xpub_json: `${dataApi}${s}.json`,
      },
    });
  }
}
async function getData() {
  if (s) {
    console.log('数据来源于接口请求', `${dataApi}${s}.json`);
    try {
      let res = {};
      let resData = {};
      /** 存在活动id，通过接口下发json **/
      if (activity_id) {
        res = await getXpubConfig({ pageId: activity_id });
      }
      /** 接口获取json失败 **/
      if (!res?.data?.xpubConfig?.scenes) {
        /** 增加埋点 **/
        XpubJsonMonitorBt(res);
        /** 兜底处理 **/
        res = await Dajax.get(`${dataApi}${s}.json`);
        resData = res?.data;
      } else {
        resData = res?.data?.xpubConfig;
      }
      return resData;
    } catch (err) {
      throw err;
    }
  } else if (isEnv) {
    console.log('非测试环境，页面描述信息来自mockData');
    try {
      return import('@/mockData');
    } catch (err) {
      throw err;
    }
  } else {
    console.log('非测试环境，页面描述信息不能缺失');
    throw new Error(
      `can not fetch data in ${process.env.NODE_ENV}, please check your url, it mast have s in query`
    );
  }
  // return data
}

export default getData;
