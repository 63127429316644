import { getRegion } from './locale';
const host = location.host;
const is99App = !!host.match('99app.com');
/**
 * @param data
 * @param propsStr
 * @returns {*}
 *
 * let data = { a: { b: { c: {} } } }
 * getProp(data, 'a.b.c')
 */
function getProp(data, propsStr = '') {
  let props = propsStr.split('.').filter((_) => _ !== '');
  let tmp = data;
  try {
    for (let i = 0; i < props.length; i++) {
      tmp = tmp[props[i]];
    }
    return tmp;
  } catch (e) {
    return void 0;
  }
}

function setBodyBackGroundColor(json) {
  let backgroundColor = getProp(json, 'scenes.style.backgroundColor');
  if (backgroundColor !== void 0) {
    document.body.style.backgroundColor = backgroundColor;
  }
  // 设置默认背景色
  document.body.style.backgroundColor = 'white';
}

function setTitle() {
  const region = getRegion();
  if (region === 'BR' || is99App) {
    document.title = '99 Food';
  } else {
    document.title = 'DiDi Food';
  }
}

export default function initStyle(json) {
  setBodyBackGroundColor(json);
  setTitle();
}
