import didiaxios from '@didi/dajax/dist/dajax';
import apiConfig from './apiConfig';
import qs from 'qs';
import { inNative } from '../lib/utils/env';

const BASE_URL = apiConfig.BASE_API_ACT;
let GET_XPUB_CONFIG = BASE_URL + '/act-api/api/landingpage/xpubConfig';

// if (process.env.NODE_ENV === 'development') {
//   GET_XPUB_CONFIG = 'https://mock.xiaojukeji.com/mock/12440/act-api/api/landingpage/xpubConfig';
// }

export const getXpubConfig = function (data) {
  return requestPOST(GET_XPUB_CONFIG, data);
};

async function requestPOST(url, data) {
  let globalParams = inNative ? (await window.SodaBridge('getGlobalParams', 'badcase')) || {} : {};
  let paramsData = globalParams.data || {};
  return didiaxios
    .request({
      url: url,
      method: 'POST',
      emulateJSON: true,
      needEncrypt: true,
      params: '',
      body: qs.stringify({ ...paramsData, ...data }),
    })
    .then((res) => res.data)
    .catch((err) => {
      console.log(err);
    });
}
