const LPHost = window.location.host;
/**
 * 判断环境
 */
export const isEnv =
  LPHost.indexOf('didi-food.com') == -1 ||
  ['test', 'development'].indexOf(process.env.NODE_ENV) >= 0;

export const isOnlineSchema =
  LPHost.indexOf('didi-food.com') !== -1 || process.env.API_ENV === 'production';

export const isSim = LPHost.startsWith('sim');
export const isPre = LPHost.indexOf('pre.didi-food.com') !== -1;
