const ls = 'localStorage' in global && global.localStorage ? global.localStorage : '';

// 存数据
export const setLocalStorage = (key, value) => {
  if (!key) return;
  value = JSON.stringify(value);
  ls.setItem(key, value);
};

// 取数据
export const getLocalStorage = (key) => {
  if (!ls.getItem || !key) return;
  if (ls.getItem(key) === '') {
    return '';
  }
  try {
    return JSON.parse(ls.getItem(key));
  } catch {
    return ls.getItem(key);
  }
};

// 删数据
export const delLocalStorage = (key) => {
  if (!key) return;
  ls.removeItem(key);
};

// 全部清空
export const clearLocalStorage = () => {
  ls.clear();
};
