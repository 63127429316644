const ua = navigator.userAgent;
export const isMobile = () => {
  let isMobi = true;
  isMobi =
    ua
      .toLowerCase()
      .match(
        /(ipod|ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i
      ) != null;
  return isMobi;
};

export const getNetworkType = () => navigator?.connection?.effectiveType;

// 操作系统类型
// 0：default
// 1：ios
// 2：android
// 9 ：windows
// 10 ：macOs
export const getDevice = () => {
  const agent = navigator.userAgent.toLowerCase();
  const result = {
    device: (function () {
      if (/windows/.test(agent)) {
        return 9;
      }
      if (/iphone|ipod|ipad/.test(agent) && /mobile/.test(agent)) {
        return 1;
      }
      if (/android/.test(agent) && /mobile/.test(agent)) {
        return 2;
      }
      if (/mac/.test(agent)) {
        return 10;
      }
      return 0;
    })(),
  };
  return result?.device;
};
