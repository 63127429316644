import { inDidiApp, inSoda } from '../utils/env.js';
// 独立端ua: "ua：" "Mozilla/5.0 (iPhone; CPU iPhone OS 16_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Fusionkit/1.2.14 Soda.Customer/2.0.46.61918101 OffMode/0"

// 嵌入端出行ua: "ua：" "Mozilla/5.0 (iPhone; CPU iPhone OS 16_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) FusionKit/1.2.14 didi.passenger.global/7.3.88 OffMode/0"

// 嵌入端 外卖appua: "ua：" "Mozilla/5.0 (iPhone; CPU iPhone OS 16_5_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Mobile/15E148 Fusionkit/1.2.14 Soda.EmbedCustomer/7.3.88.61322081 OffMode/0"

export const interceptDialog = () => {
  // 在出行容器下打开落地页页面，弹一个弹窗
  if (inDidiApp) {
    alert('当前容器暂不支持打开落地页页面');
  }
};

export const isEmbedded = inDidiApp && !inSoda;
