import { isOnlineSchema, isPre } from '../lib/js/env';
let simName = location.host.split('.')[0];
// let simName = 'sim060';
// let deVsimName = 'sim266';
let BASE_API_ACT = 'https://act-api.didi-food.com';
if (isPre) {
  BASE_API_ACT = 'https://pre-act-api.didi-food.com';
} else if (isOnlineSchema) {
  BASE_API_ACT = 'https://act-api.didi-food.com';
} else {
  BASE_API_ACT = `http://${simName}.rlab.sim.intra.xiaojukeji.com/act-api`;
}

export default {
  BASE_API_ACT,
};
